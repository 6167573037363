import React, {Component} from "react";
import moment from "moment";
import "moment-timezone";
import {connect} from "react-redux";
import {getTime} from "../IOT/device-functions/GatewayFunctions";
import devicesDB from "../database/devicesDB";
import {DevType} from "../constans/devices";
import _ from "lodash";
import PropTypes from "prop-types";
import {getFarmZoneType} from "../utils/TimeUtils";
import {isUsingFakeData} from "../utils/SettingsUtils";

@connect((store) => {
    return {
        user: store.user.user,
        farmID: store.location.farm,
        delay: store.time.piDelay,
        devices: store.farmDevices.devices
    };
})
export class Clock extends Component {

    constructor(props) {
        super(props);
        const {delay} = props;
        this.tick = this.tick.bind(this);
        this.onFarmChange = this.onFarmChange.bind(this);
        this.requestGatewayTime = this.requestGatewayTime.bind(this);
        this.state = {
            date: moment().subtract(delay || 0, "ms"),
            sameTimezone: true
        }
    }

    requestGatewayTime(props) {
        if (!isUsingFakeData()) {
            const {farmID, dispatch} = props;
            dispatch({
                type: "ZERO_PI_TIME"
            });
            if (farmID) {
                let gateway = devicesDB.getDevicesByAttributes({FarmID: farmID, DevType: DevType.GATEWAY})[0];
                if (gateway) {
                    dispatch({
                        type: "GET_PI_TIME_REQUEST",
                        payload: +moment(),
                    });
                    getTime(gateway, (message) => {
                        dispatch({
                            type: "GET_PI_TIME_RESPONSE",
                            payload: message.CAnsw.time
                        })
                    }, () => dispatch({
                        type: "ZERO_PI_TIME"
                    }))
                }
            }
        }
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
        if (!isUsingFakeData()) {
            this.requestGatewayTime(this.props);
        }
        this.onFarmChange(this.props);

    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {devices, farmID} = this.props;
        if (!_.isEqual(devices, nextProps.devices) || !_.isEqual(farmID, nextProps.farmID)) {
            this.requestGatewayTime(nextProps);
        }
        if (!_.isEqual(farmID, nextProps.farmID)) {
            this.onFarmChange(nextProps);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    onFarmChange(props) {
        const {farmID} = props;
        let localTimezone = moment.tz.guess(true);
        let farmTimezone = getFarmZoneType(farmID);
        moment.defaultFormat = "DD.MM.YY HH:mm";
        moment.defaultFormatUtc = "DD.MM.YY HH:mm";
        moment.tz.setDefault(farmTimezone);
        this.setState({
            sameTimezone: farmTimezone === localTimezone
        });
    }

    tick() {
        const {delay} = this.props;
        this.setState({
            date: moment().subtract(delay || 0, "ms"),
        });
    }

    render() {
        const {sameTimezone, date} = this.state;
        const {format, customComponent} = this.props;
        if (!customComponent) {
            return (
                <time>
                    {
                        sameTimezone &&
                        <React.Fragment>
                            <strong>{date.format(format)}</strong>
                        </React.Fragment>
                    }
                    {
                        !sameTimezone &&
                        <React.Fragment>
                            <i className="fas fa-fw fa-home"/>{' '}<strong>{date.format(format)}</strong>{'  '}
                            <br/>
                            <i className="fas fa-fw fa-map-marker"/>{' '}{moment.tz(date, moment.tz.guess(true)).format(format)}
                        </React.Fragment>
                    }
                </time>
            )
        } else {
            return React.createElement(customComponent, {time: +date})
        }

    }
}

Clock.propTypes = {
    format: PropTypes.string,
    customComponent: PropTypes.oneOf([PropTypes.node, PropTypes.func])
}

Clock.defaultProps = {
    format: "HH:mm:ss"
};

export default Clock;
